import './assets/main.css'
import './styles/components/buttons.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
/*import * as Sentry from "@sentry/vue";*/

import './styles/bootstrap/bootstrap.scss'
import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap

const app = createApp(App)

app.use(router)


/*Sentry.init({
    app,
    dsn: "https://ca44bc288f2641de96dfe0fed55d5828@o1096478.ingest.sentry.io/4505552739303424",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "cutotopo.live", /^\//],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
        ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});*/

app.mount('#app')
