<template>
  <div class="text-center">
    <img style="border-radius: 100%; height: 64px;" src="https://avatars.githubusercontent.com/u/44125124?v=4" alt="GitHub profile picture">
    <h4 class="mt-2">Hi! I'm Cutotopo!</h4>
    <p>
      Welcome to my website!<br>
      Here you will find instructions, how-tos, links to source code and downloads for all my apps and scripts.<br><br>
      Documentation for my projects can be viewed at <a href="https://docs.cutotopo.live" target="_blank"><span class="mdi mdi-open-in-new"></span>docs.cutotopo.live</a>.<br><br>
      <RouterLink to="/blog/post/website-redesign">This project is not 100% complete.</RouterLink><br><br>
      <RouterLink to="/privacy">Privacy notice</RouterLink>
    </p>
  </div>
</template>