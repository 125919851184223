<script setup>
import navigation from '../utilities/navigation';
</script>

<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <button class="btn" style="background-color: transparent; border: 0px solid transparent; margin: 0; padding: 0;">
        <img src="https://avatars.githubusercontent.com/u/44125124?v=4" alt="GitHub profile picture" height="24" class="d-inline-block align-text-top me-2" style="border-radius: 100%; transform: translateY(-3px); aspect-ratio: 1 / 1 !important;">
        <a class="navbar-brand" href="#" data-bs-toggle="collapse" data-bs-target=".navigation">Cutotopo</a>
      </button>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-for="nav in navigation" v-if="$route.name">
            <RouterLink class="nav-link" :class="getNavClasses(nav)" aria-current="page" :to="nav.route"><span :class="nav.icon"></span> {{ nav.label }}</RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    getNavClasses: function(nav) {
      let classes = ""
      if (this.$route.name.startsWith(nav.check)) {
        classes += ' active'
      }
      if (nav.disabled) {
        classes += ' disabled'
      }
      return classes
    }
  }
}
</script>