<script setup>
import anime from 'animejs/lib/anime.es.js';
</script>

<template>
    <div class="text-center nav-link fs-3 mt-3 mb-3" data-bs-toggle="collapse" data-bs-target=".navigation">
        <img src="https://avatars.githubusercontent.com/u/44125124?v=4" height="32" alt="GitHub profile picture" class="d-inline-block align-text-top me-2 headerPic" style="border-radius: 100%; transform: translateY(3px);">
        <div class="d-inline headerLabel">Cutotopo</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAvatarVisible: true
        }
    },
    methods: {
        hideAvatar() {
            if (this.isAvatarVisible) {
                anime({
                    targets: '.headerPic',
                    height: 0,
                    width: 0,
                    easing: 'easeInOutExpo'
                })
                anime({
                    targets: '.headerLabel',
                    marginRight: 8,
                    easing: 'easeInOutExpo'
                })
                this.isAvatarVisible = false;
            }
        },
        showAvatar() {
            if (!this.isAvatarVisible) {
                anime({
                    targets: '.headerPic',
                    height: 32,
                    width: 32,
                    easing: 'easeInOutExpo'
                })
                anime({
                    targets: '.headerLabel',
                    marginRight: 0,
                    easing: 'easeInOutExpo'
                })
                this.isAvatarVisible = true;
            }
        }
    },
    watch: {
        $route (to, from) {
            if (to.name == "home") {
                this.hideAvatar();
            } else {
                this.showAvatar();
            }
        }
    },
    mounted() {
        if (this.$route.name == "home") {
            this.hideAvatar()
        }
    }
} 
</script>