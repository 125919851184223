const navigation = [
    {
      label: "Home",
      icon: "mdi mdi-home-outline",
      route: "/",
      check: "home"
    },
    {
      label: "Projects",
      icon: "mdi mdi-dock-window",
      route: "/projects",
      check: "projects"
    },
    {
      label: "Socials",
      icon: "mdi mdi-web",
      route: "/socials",
      check: "socials"
    },
    {
      label: "Contact",
      icon: "mdi mdi-email-outline",
      route: "/contact",
      check: "contact"
    },
    {
      label: "Blog",
      icon: "mdi mdi-newspaper",
      route: "/blog",
      check: "blog",
      disabled: false
    }
]

export default navigation