import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  beforeRouteUpdate: function() {
    console.log("update")
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('../views/ProjectsView.vue')
    },
    {
      path: '/docs',
      name: 'docs',
      component: () => import('../views/DocsView.vue')
    },
    {
      path: '/socials',
      name: 'socials',
      component: () => import('../views/SocialsView.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/ContactView.vue')
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('../views/blog/BlogView.vue')
    },
    {
      path: '/blog/post/:postid',
      name: 'blog-post',
      component: () => import('../views/blog/BlogPostView.vue')
    },
    {
      path: '/blog/author/:authorid',
      name: 'blog-author',
      component: () => import('../views/blog/BlogAuthorView.vue')
    },
    {
      path: '/blog/tag/:tagid',
      name: 'blog-tag',
      component: () => import('../views/blog/BlogTagView.vue')
    },
    {
      path: '/pages/:postid',
      name: 'pages-post',
      component: () => import('../views/PagesArticleView.vue')
    },
    {
      path: '/go/:plat',
      name: 'go',
      component: () => import('../views/GoView.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('../views/legal/PrivacyNotice.vue')
    },
    {
      path: '/:notFound',
      component: () => import('../views/NotFound.vue')
    }
  ]
})

export default router
