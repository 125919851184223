<script setup>
import navigation from '../utilities/navigation';
</script>

<template>
    <ul class="nav nav-pills justify-content-center">
      <li class="nav-item" v-for="nav in navigation" v-if="$route.name">
        <RouterLink class="nav-link" :class="getNavClasses(nav)" aria-current="page" :to="nav.route"><span :class="nav.icon"></span> {{ nav.label }}</RouterLink>
      </li>
    </ul>
</template>

<script>
export default {
  methods: {
    getNavClasses: function(nav) {
      let classes = ""
      if (this.$route.name.startsWith(nav.check)) {
        classes += ' active'
      }
      if (nav.disabled) {
        classes += ' disabled'
      }
      return classes
    }
  }
}
</script>